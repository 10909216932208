import jsPDF from "jspdf"
import { Item } from "model";
import Logo from "../../components/logo1024.png"

class ProposalPDF {
    doc: jsPDF
    x: number = 10
    y: number = 10
    stage: string = ""

    constructor(logo: string){
        this.doc = new jsPDF({
            orientation: "portrait",
            unit: "mm",
            format: "letter"
        }); 
        
        this.drawHeader()
        this.drawProjectInfo()
        this.drawFooter(1, 1)
    }

    drawHeader = () => {
        this.doc.setFont("helvetica", "bold");
        this.doc.setFontSize(32);
        
        this.doc.addImage(Logo, "png", 152, this.y, 48, 48)

        this.y += 40

        this.doc.text("Proposal for Client", 10, this.y);

    
        this.y += 10
        
        this.doc.setDrawColor("#959595")
        this.doc.line(10, 60, 200, this.y)
        // this.doc.setDrawColor("#000000")

        this.y += 10
    }

    drawProjectInfo = () => {
        this.doc.setFont("helvetica", "normal");
        this.doc.setFontSize(12);
        this.doc.setTextColor("#959595")

        this.doc.text("Project Name", this.x, this.y)
        this.doc.text("Shoot Date", this.x + 75, this.y)
        this.doc.text("Deliverable", this.x + 75 + 75, this.y)

        this.y += 10
        
        this.doc.setTextColor("#000000")
        this.doc.text("Testing", this.x, this.y)
        this.doc.text("Testing", this.x + 75, this.y)
        this.doc.text("Testing", this.x + 75 + 75, this.y)

        this.y += 10

        this.doc.setTextColor("#959595")
        this.doc.text("Email", this.x, this.y)
        this.doc.text("Location", this.x + 75, this.y)

        this.y += 10

        this.doc.setTextColor("#000000")
        this.doc.text("Testing", this.x, this.y)
        this.doc.text("Testing", this.x + 75, this.y)

        this.y += 10
    }

    drawFooter = (page: number, totalPage: number) => {
        this.doc.setFont("helvetica", "normal");
        this.doc.setFontSize(12);
        this.doc.line(10, 260, 200, 260)
        this.doc.text("Generated by PipApp", 10, 267);
        this.doc.text(`Page ${page} of ${totalPage}`, 178, 267);
    }

    drawSectionHeader = (name: string) => {
        this.doc.setFont("helvetica", "normal");
        this.doc.setFontSize(12)
        this.doc.setTextColor("#959595")
        this.doc.text(name, this.x, this.y)

        this.doc.text("Sub-Total", 160, this.y)

        this.y += 5
        this.doc.line(this.x, this.y, 200, this.y)
        this.y += 10

        this.doc.setTextColor("#000000")
    }

    drawTotal = () => {
        this.doc.setFont("helvetica", "normal");
        this.doc.setFontSize(16);
        this.doc.line(10, this.y, 200, this.y)
        this.y += 10
        this.doc.text("Total Estimate:", 120, this.y)
        this.doc.text("$100.00", 160, this.y)
    }
    

    addItem = (item: Item) => {
        
        if(this.stage !== item.category){

            this.y += 10

            this.drawSectionHeader(item.category)
            this.stage = item.category
        }

        this.doc.setFont("helvetica", "normal");
        this.doc.setFontSize(12);
    
        this.doc.text(item.name, this.x + 5, this.y)

        let price = (item.cost).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        })

        this.doc.text(price, 160, this.y)

        if(this.y > 280){
            this.addPage()
        }
    }

    addPage = () => {
        this.doc.addPage()
        
        this.y = 10

        this.drawHeader()
        this.drawFooter(1, 1)

    }

    addSpace = (space: number) => {
        this.y += space
    }

    download = () => {
        this.doc.save("download.pdf");
    }
}

export default ProposalPDF