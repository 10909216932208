import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import LoginPage from 'pages/login'
import SignUpPage from 'pages/sign-up/SignUp'

const PublicRoutes = () => {
    return (
        <Routes>
            <Route path="signup" element={<SignUpPage/>}/>
            <Route index element={<LoginPage/>}/>
            {/* <Navigate to="login" /> */}
        </Routes>
    )
}

export default PublicRoutes;