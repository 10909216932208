import React, {useContext, useState} from 'react';
import Grid from '@mui/material/Grid';

import {Item} from 'model';
import StageResource from './StageResource';
import CustomItemButton from './CustomItemButton'
import {CustomItemDialog} from "./CustomItemDialog";
import BudgetPlanContext from "../BudgetPlanContext";
import EditItemDialog from "./EditItemDialog";


interface Props {
    stage: string,
}

const StageBuilder: React.FC<Props> = (props: Props) => {
    const { resources, addItems, selectItem, deselectItem, removeItems, updateItem } = useContext(BudgetPlanContext)
    const [items, setItems] = useState<Item[]>([])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [editItem, setEditItem] = useState<Item>()

    const getAvailableItemByStage = (name: string) => {
        return resources.filter( i => i.category === name )
    }

    const handleItemClick = (item: Item) => {
        if(item.selected){
            deselectItem(item)
        }else{
            selectItem(item)
        }
    }

    const handleItemEdit = (item: Item) => {
        setEditItem(item)
    }

    const handleItemRemove = (item: Item) => {
        removeItems([item])
    }

    const handleItemUpdate = (item: Item) => {
        updateItem(item)
        setEditItem(undefined)
    }

    const handleCustomItemButtonClick = () => {
        setDialogOpen(!dialogOpen)
    }

    const handleCustomItemSave = (newItems: Item[]) => {
        console.log(newItems)
        newItems.filter( i => i.name !== undefined && i.name !== "")
            .forEach( i => i.category = props.stage)
        setItems([...items, ...newItems])
        addItems(newItems)
        setDialogOpen(false)
    }

    return (
        <React.Fragment>
            {/*<Typography>Edit Mode</Typography>*/}
            {/*<Switch*/}
            {/*    checked={edit}*/}
            {/*    onChange={() => setEdit(!edit)}*/}
            {/*    color="primary"*/}
            {/*    name="editMode"*/}
            {/*/>*/}
            <Grid container spacing={2} alignItems="stretch">
                { getAvailableItemByStage(props.stage).map( (i, idx) =>
                    <Grid key={idx} item xs={6} sm={4} md={3}>
                        <StageResource
                            item={i}
                            selected={i.selected}
                            editMode={true}
                            onItemClick={() => handleItemClick(i)}
                            onItemEdit={(item) => handleItemEdit(item)}
                            onItemRemove={() => handleItemRemove(i)}/>
                    </Grid> )
                }
                <Grid item xs={6} sm={4} md={3}>
                    <CustomItemButton onClick={() => handleCustomItemButtonClick()}/>
                </Grid>
            </Grid>
            <CustomItemDialog
                items={getAvailableItemByStage(props.stage)}
                open={dialogOpen}
                save={handleCustomItemSave}
                onClose={() => setDialogOpen(false)}
            />
            <EditItemDialog
                open={editItem !== undefined}
                target={editItem}
                onSave={(item) => handleItemUpdate(item)}
                onClose={() => setEditItem(undefined)}
            />
        </React.Fragment>
    )
}

export default StageBuilder;