import React from 'react';
import NavBar from "../../components/NavBar";
import {Card, Container, Typography} from "@mui/material";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import CssBaseline from "@mui/material/CssBaseline";
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles((theme) => ({
    body: {
        fontSize: "20px",
        color: "black"
    },

    layout: {

        width: 'auto',
        height: '100%',
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        background: '#F8F8F8'
    },
    paymentTopContainer: {
        background: "#FFF",
        padding: "3rem",
    },
    root: {
        flexGrow: 1,
    },
    paper: {
        width: "100%",
        textAlign: 'left',
        border: "2px solid",
        borderRadius: "16px",
        height: "20rem"
    }, paperSelect: {
        width: "100%",
        textAlign: 'left',
        border: "2px solid",
        borderColor: "#04AFAA",
        borderRadius: "16px",
        height: "20rem",


    }, boarderBox: {

        color: "#111",
        width: "100%",
        alignSelf: 'center'
    }, radiusBoxTitle: {
        marginTop: "1rem",
        fontSize: "18px",
        fontWeight: 600
    }, radiusBoxTitleTop: {
        marginBottom: "1rem",
        fontSize: "18px",
        fontWeight: 600
    }, CurrentCardbox: {
        display: "Flex",
        padding: "20px",
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: "18px",

    }, CurrentCardboxNotSelect: {
        display: "none",
        padding: "20px",
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: "18px"
    }, startformEnd: {
        alignItems: "end",
        textAlign: "right",
        padding: "0px",
        width: "100%"

    }, submitButton: {
        marginTop: "20px",
        padding: "5px",
        width: "100%"
    }, radioButton: {
        color: "#111"
    }, planTitle: {
        color: "#ff9900",
        fontSize: "20px",
        fontWeight: 700,
        marginBottom: "5px"
    }, planPrice: {
        fontSize: "18px",
        fontWeight: 600,
        marginBottom: "5px"
    }, plandiv: {
        height: "15rem",
        padding: "2rem",
    }, cardNotSelect: {
        display: "none"
    }, cardSelect: {

    }, iconlocation: {
        marginTop: "30px",
        marginRight: "-17px",

    }, svg: {
        fill: "#04AFAA"
    }, svgNone: {
        display: "none"
    }

}));

const stripePromise = loadStripe('pk_test_51GryX9DOrDfLjHbihp3Pf8J18yfaYs4VWhkxmrXesvJ7Nk5GWXyvLNkHHx3ss2EWI4mZeJEaxk8aVNUYgnw2Gt6i00zE243Md3');

const PaymentMethodPage = () => {

    const [value, setValue] = React.useState('0');
    const [plan, setplan] = React.useState(true);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };
    const classes = useStyles();
    return <>
        <CssBaseline />
        <NavBar />
        <Container maxWidth="md" className={classes.body}  >
            <Box boxShadow={3}>
                <Box mt={12} className={classes.paymentTopContainer}>
                    <div className={classes.root}>
                        <Typography className={classes.radiusBoxTitleTop}>Please Select a Plan:</Typography>
                        <Grid container spacing={4}>
                            <Grid item xs>
                                <Button className={plan ? classes.paperSelect : classes.paper} onClick={() => setplan(true)} >
                                    <div className={classes.plandiv}>
                                        <Typography className={classes.planTitle}>Monthly Creator</Typography>
                                        <Typography className={classes.planPrice}>$14/month</Typography>
                                        <Typography>For the small but mighty creators. This comes with login that you can use for all your budgeting needs</Typography>
                                        <Grid container justifyContent="space-between" alignItems="flex-end">
                                            <Grid item>

                                            </Grid>
                                            <Grid item className={classes.iconlocation}>
                                                <CheckCircleIcon className={plan ? classes.svg : classes.svgNone} />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Button>
                            </Grid>
                            <Grid item xs>
                                <Button className={plan ? classes.paper : classes.paperSelect} onClick={() => setplan(false)}>
                                    <div className={classes.plandiv}>
                                        <Typography className={classes.planTitle}>Annual Creator</Typography>
                                        <Typography className={classes.planPrice}>$140/year</Typography>
                                        <Typography>Get 2 months free by purchasing an annual subscription and save even more by using PipApp for all your project budgeting.</Typography>
                                        <Grid container justifyContent="space-between" alignItems="flex-end">
                                            <Grid item>

                                            </Grid>
                                            <Grid item className={classes.iconlocation}>
                                                <CheckCircleIcon className={plan ? classes.svgNone : classes.svg} />
                                            </Grid>
                                        </Grid>
                                    </div>

                                </Button>
                            </Grid>
                        </Grid>
                        <FormControl component="fieldset" className={classes.boarderBox}>
                            <Typography className={classes.radiusBoxTitle}>Please Select Your Card:</Typography>
                            <RadioGroup aria-label="CardSelect" name="cardselect1" value={value} onChange={handleChange} >
                                <FormControlLabel value="0" control={<Radio color="default" />} label="Current Card" />
                                <Box boxShadow={1} className={(value == '0') ? classes.CurrentCardbox : classes.CurrentCardboxNotSelect} > VISA<Typography className={classes.startformEnd} >**** **** 4242</Typography></Box>
                                <FormControlLabel value="1" control={<Radio color="default" />} label="New Card" />
                            </RadioGroup>
                            <Card className={(value == '1') ? classes.cardSelect : classes.cardNotSelect}>
                                {/* <Elements stripe={stripePromise}> */}
                                    <CheckoutForm />
                                {/* </Elements> */}
                            </Card>
                            <Button type="submit" variant="outlined" color="primary" className={classes.submitButton} >Submit</Button>
                        </FormControl>

                    </div>



                </Box>
            </Box>

        </Container>
    </>;
}

export default PaymentMethodPage