import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {Item, Project} from 'model'
import StageBuilder from 'pages/butget-planner/stage-builder/StageBuilder';
import CostEstimator from 'pages/butget-planner/cost-estimator/CostEstimator';
import {Container} from "@mui/material";
import Card from '@mui/material/Card'
import Divider from "@mui/material/Divider";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NavBar from "components/NavBar";
import * as ProjectApi from 'api/ProjectApi';
import Proposal from "pages/butget-planner/Proposal";
import BudgetPlanStepper from "./BudgetPlanStepper";
import BudgetPlanContext from "./BudgetPlanContext";
import { v4 as uuid } from "uuid"

const useStyles = makeStyles((theme) => ({
    app: {},
    appBar: {
        boxShadow: '0px 3px 6px #C4C4C429'
    },
    layout: {
        width: 'auto',
        height: '100%',
        marginTop: -theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        background: theme.palette.background.default
    },
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    title: {
        flexGrow: 1,
        color: "#577590",
        textTransform: "uppercase"
    },
    step: {
        background: '#F8F8F8'
    },
    card: {
        marginBottom: '1em',
        padding: theme.spacing(4),
        boxShadow: "0px 3px 6px #C4C4C429"
    },
}));

function getSteps() {
    return [
        'Pre Production',
        'Production',
        'Post Production',
        'Equipment',
        'Travel',
        'Miscellaneous'
    ];
}

const BudgetPlanner = () => {
    const { projectId } = useParams();
    const [project, setProject] = useState<Project>()
    const [resources, setResources] = useState<Item[]>([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const [markup, setMarkup] = useState(0)
    const steps = getSteps();

    useEffect(() => {
        (async () => {
            if(!projectId) return
            console.debug(projectId)
            let project = await ProjectApi.getProjectById(projectId)
            setProject(project)

            // Hotfix: assign id to old format project items
            project.resources.forEach( r => {
                if(r.id === undefined){
                    r.id = uuid()
                }
            })

            setResources(project.resources)
            setActiveStep(project.currentStep)
            setMarkup(project.markup)
        })();
    }, [projectId])

    const handleBack = async () => {
        if(!projectId) return
        await ProjectApi.updateResource(projectId, Object.values(resources), activeStep - 1)
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    const handleNext = async () => {
        if(!projectId) return
        await ProjectApi.updateResource(projectId, Object.values(resources), activeStep + 1)
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleGoTo = async (step: string) => {
        if(!projectId) return
        let idx = getSteps().findIndex( s => s === step)
        await ProjectApi.updateResource(projectId, resources, idx)
        setActiveStep(idx)
    }

    const addItems = async (items: Item[]) => {
        if(!projectId) return
        setResources([...resources, ...items])
        await ProjectApi.updateResource(projectId, resources, activeStep)
        console.log(resources)
    }

    const removeItems = async (items: Item[]) => {
        if(!projectId) return
        setResources(resources.filter(item => items.every(i => i.id !== item.id)))
        await ProjectApi.updateResource(projectId, resources, activeStep)
    }

    const selectItem = (item: Item) => {
        let idx = resources.findIndex( i => i.id === item.id)
        item.selected = true
        resources[idx] = item
        setResources([...resources])
    }

    const deselectItem = (item: Item) => {
        let idx = resources.findIndex( i => i.id === item.id)
        item.selected = false
        resources[idx] = item
        setResources([...resources])
    }

    const updateItem = (item: Item) => {
        let idx = resources.findIndex( i => i.id === item.id)
        resources[idx] = item
        setResources([...resources])
    }

    const updateMarkup = async (markup: number) => {
        setMarkup(markup)
        if(project) {
            project.markup = markup
        }
        await ProjectApi.updateProjectMarkup(project!!, markup)
    }

    const classes = useStyles();
    return (
        <div className={classes.app}>
            <CssBaseline />
            <NavBar />
            <BudgetPlanContext.Provider value={{
                resources: Object.values(resources),
                addItems: addItems,
                removeItems: removeItems,
                selectItem: selectItem,
                deselectItem: deselectItem,
                updateItem: updateItem
            }}>
                <main className={classes.layout}>
                    {activeStep < getSteps().length ?
                        <Container maxWidth="lg">
                            <BudgetPlanStepper
                                activeStep={activeStep}
                                steps={steps}
                                onStepClick={(step) => handleGoTo(step)}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8}>
                                    <Card className={classes.card}>
                                        <Typography variant="h6" className={classes.title}>
                                            Project Details
                                        </Typography>
                                        <Divider variant="fullWidth" />
                                        <br />
                                        <Grid container>
                                            <Grid item xs={3}>
                                                <Typography>Client Name</Typography>
                                                {project?.client_name}
                                            </Grid>
                                            <Grid item xs={3}>
                                                <>
                                                    <Typography>Shoot Date</Typography>
                                                    {project?.shoot_date}
                                                </>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography>Location</Typography>
                                                {project?.location}
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography>Deliverable</Typography>
                                                {project?.deliverable}
                                            </Grid>
                                        </Grid>
                                    </Card>
                                    <Card className={classes.card}>
                                        <Box mb={2}>
                                            <Typography variant="h3">
                                                {activeStep + 1}. {getSteps()[activeStep]}
                                            </Typography>
                                        </Box>
                                        <Box mb={4}>
                                            <StageBuilder stage={getSteps()[activeStep]}/>
                                        </Box>
                                        <Box>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item xs={6}>
                                                    { activeStep > 0 &&
                                                    <Button color="primary"
                                                        size="small"
                                                        variant="contained"
                                                        onClick={handleBack}
                                                        style={{ padding: "8px 24px" }}
                                                        startIcon={<ArrowBackIcon />}
                                                    >
                                                        Back
                                                    </Button>
                                                    }
                                                </Grid>
                                                <Grid item>
                                                    <Button color="primary"
                                                        size="small"
                                                        variant="contained"
                                                        onClick={handleNext}
                                                        style={{ padding: "8px 24px" }}
                                                        endIcon={<ArrowForwardIcon />}
                                                    >
                                                        Next
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Card>
                                        <CostEstimator resources={resources}
                                                       markup={project?.markup ?? 0}
                                                       onMarkUpSave={(markup) => updateMarkup(markup)}/>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>
                      : <Proposal onBack={() => handleBack()} resources={resources} project={project} />
                    }
                </main>
            </BudgetPlanContext.Provider>
        </div>
    );
}

export default BudgetPlanner