import React, {useEffect} from 'react';
import NavBar from "../../components/NavBar";
import {Button, Container} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    body: {
        fontSize: "20px",
        shadowColor: '#000',
        shadowOffset: {width: 1, height: 1},
        shadowOpacity: 1,
    }
}));

const PaymentPage = () => {
    const history = useNavigate()

    useEffect(() => {

    }, [])

    const onChangePlanClick = () => {
        history("/billings")
    }

    const classes = useStyles();
    return (
        <>
            <CssBaseline/>
            <NavBar/>
            <Container maxWidth="sm" className={classes.body}>
                <Paper elevation={2}>
                    <Box p={4}>
                        <Typography variant="h1">Account Settings</Typography>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h5">Current Plan</Typography>
                            <Typography>$10/Month</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h5">Credit Card</Typography>
                            <Typography>Visa **** 4242</Typography>
                        </Box>
                        <Box>
                            <Button variant="contained" size="small" color="primary" onClick={() => onChangePlanClick()}>Change Plan</Button>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </>
    )
}

export default PaymentPage