export enum ItemType {
    HOURLY = "HOURLY",
    FLAT_RATE = "FLAT_RATE"
}

export interface Item {
    id: string
    name: string
    category: string
    cost: number
    selected: boolean,
    type: ItemType,
    hours?: number
}

export interface Project {
    user_id: string,
    project_id: string,
    project_name: string
    client_name: string,
    client_email: string,
    shoot_date: Date,
    team: string,
    location: string,
    description: string,
    deliverable: string,
    resources: Item[],
    estimate: number,
    currentStep: number
    created_at: Date,
    markup: number
}

export interface proposal{
    proposalid : string,
    projectId: string,
    imgbase64: string,
    creat_at: Date
}

export interface StripeUser {
    customer_id: string,
    setup_secret: string
}

export type SubscriptionPlan = {
    id: string,
    name: string,
    description: string,
    price: string,
}
