import React, {useState} from 'react'
import {Box, Button, Divider, Grid, Hidden, TextField, Typography} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import {auth, facebookProvider, googleProvider} from 'utils/firebase'
import {ContinueWithFacebook, ContinueWithGoogle} from 'components/buttons/Buttons'


const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%'
    },
    left: {
        background: theme.palette.background.default,
        height: '100%',
        padding: '5em'
    },
    right: {
        background: 'black',
        backgroundImage: "url(/pip_hero.jpg)",
        height: '100%',
        backgroundPosition: "center center",
        backgroundSize: "cover"
    },
    logo: {
        display: 'block',
        width: '128px',
        height: '128px',
        backgroundSize: 'cover',
        backgroundColor: '#04AFAA',
        backgroundPosition: 'center center',
        backgroundImage: "url(/logo1024.png)",
        boxShadow: "0px 5px 10px #47474726",
        borderRadius: "64px"
    },
    formInput: {
        color: "#03363d"
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: "white",
        background: "#06b1a5",
        '&:hover': {
            background: "#06b1a5"
        }
    },
}));


const LoginPage = () => {
    const classes = useStyles();

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const signIn = (event: any) => {
        event.preventDefault()
        console.log("signIn")
        auth.signInWithEmailAndPassword(username, password);
    }

    const signInWithGoogle = () => {
        auth.signInWithPopup(googleProvider)
    }

    const signInWithFacebook = () => {
        auth.signInWithPopup(facebookProvider)
    }

    return <>
        <Grid container className={classes.container}>
            <Grid className={classes.left} item xs={12} md={4}>
                <div className={classes.logo} />
                <Typography variant="h3">Log in</Typography>
                <form className={classes.form} noValidate onSubmit={signIn}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={(e) => setUsername(e.target.value)}
                        InputLabelProps={{
                            required: false,
                            shrink: true,
                            className: classes.formInput
                        }}
                    />
                    <TextField className={classes.formInput}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                        InputLabelProps={{
                            required: false,
                            shrink: true,
                        }}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        color="primary"
                        variant="text"
                        disableElevation={true}
                        className={classes.submit}
                    >
                        Login
                </Button>
                </form>

                <Box mt={2} mb={2}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item xs={5}>
                            <Divider variant="fullWidth" />
                        </Grid>
                        <Grid item xs={1}>
                            <Box textAlign="center">Or</Box>
                        </Grid>
                        <Grid item xs={5}>
                            <Divider variant="fullWidth" />
                        </Grid>
                    </Grid>
                </Box>

                <Box mt={2}>
                    <ContinueWithGoogle onClick={signInWithGoogle} />
                </Box>
                <Box mt={2}>
                    <ContinueWithFacebook onClick={signInWithFacebook} />
                </Box>
                <Box mt={2}>
                    <Typography>Don't have an account? </Typography>
                    
                    <a href="./signup">Sign up</a>
                </Box>
            </Grid>
        </Grid>
    </>;
}

export default LoginPage;