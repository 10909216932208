import React from 'react'

import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    default: {
        borderRadius: "16px"
    },
}))

interface CustomItemButtonProps {
    onClick: () => void
}

const CustomItemButton: React.FC<CustomItemButtonProps> = (props: CustomItemButtonProps) => {
    const classes = useStyles()
    return <Card variant="outlined" className={ classes.default }>
        <CardActionArea style={{height: "164px"}} onClick={() => props.onClick()}>
            <CardContent>Add Custom Item</CardContent>
        </CardActionArea>
    </Card>
}

export default CustomItemButton