import React from 'react'

import {useNavigate} from "react-router-dom";

import {useForm} from "react-hook-form";

import { TextField } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CheckIcon from '@mui/icons-material/Check';
import Grid from "@mui/material/Grid";

import {auth} from "utils/firebase";
import {Project} from "model";

import * as projectApi from "api/ProjectApi"

const StyledTextField = withStyles({
    root: {
        '& label.MuiFormLabel-root': {
            color: '#577590',
            fontSize: '18px'
        },
        '& label.Mui-focused': {
            color: '#577590',
        },
        '& .MuiInputBase-input': {
            paddingTop: 8,
            paddingBottom: 8,
        },
        '& .MuiInput-underline': {
            color: '#1E212B',
            fontSize: '18px'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            borderBottomColor: '#577590',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#DCDCDC',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#577590',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
    },
})(TextField);

const NewProjectForm = () => {
    const history = useNavigate()
    const { register, handleSubmit } = useForm<Project>()

    const onSubmit = async (project: Project) => {
        console.debug(project)
        project.user_id = auth.currentUser?.uid!!
        project.resources = []
        project.estimate = 0
        const result = await projectApi.createProject(project)
        history(`/project/${result.project_id}`)
    }

    const onCancel = () => {
        history("/")
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <StyledTextField inputRef={register}
                        margin="normal"
                        required
                        fullWidth
                        label="Project Name"
                        name="project_name"
                        autoComplete="email"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <StyledTextField inputRef={register}
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Client Name"
                        name="client_name"
                        autoComplete="email"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <StyledTextField inputRef={register}
                        margin="normal"
                        required
                        fullWidth
                        label="Client Email"
                        name="client_email"
                        autoComplete="email"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <StyledTextField inputRef={register}
                        margin="normal"
                        fullWidth
                        label="Shoot Date"
                        name="shoot_date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <StyledTextField inputRef={register}
                        margin="normal"
                        fullWidth
                        label="Location"
                        name="location"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StyledTextField inputRef={register}
                        margin="normal"
                        fullWidth
                        label="Description"
                        name="description"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StyledTextField inputRef={register}
                        margin="normal"
                        fullWidth
                        label="Deliverable"
                        name="deliverable"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
            <Box mt={4}>
                <Button
                    type="submit"
                    color="primary"
                    size="small"
                    variant="contained"
                    style={{marginRight: "1rem"}}
                    startIcon={<CheckIcon />}>
                    Create
                </Button>
                <Button
                    color="primary"
                    size="small"
                    variant="outlined"
                    onClick={() => onCancel()}
                >
                    Cancel
                </Button>
            </Box>
        </form>
    )
}

export default NewProjectForm