import firebase, {firestore} from "utils/firebase";
import {v4 as uuid} from "uuid";

import * as model from "model";
import {Project} from "model";

export async function updateResource(projectId: string, resources: model.Item[], currentStep: number) {

    let estimate = 0

    if(resources.filter(i => i.selected).length > 0) {
        estimate = resources
            .filter(i => i.selected)
            .map(r => r.cost)
            .reduce((sum, current) => sum + current)
    }

    let result = await  firestore.collection("projects").doc(projectId)
        .set({
            "resources": resources,
            "estimate": estimate,
            "currentStep": currentStep
        }, {merge: true})
}

export async function updateProjectMarkup(project: Project, markup: number){
    let result = await  firestore.collection("projects")
        .doc(project.project_id)
        .set({markup: markup}, {merge: true})
}

export async function createicon(projectid: string,imgbase64: string) {
    let proposalid = uuid()
    let result = await  firestore.collection("proposal").doc(proposalid)
        .set({
            "proposalid": proposalid,
            "projectId": projectid,
            "imgbase64": imgbase64,
            "creat_at": new Date()
        }, {merge: true}).then(() => {
            getIconbyId(proposalid)
          });


    
}

export async function getIconbyId(projectId: string){
    console.log('getting data')
    let result = await  firestore.collection("proposal")
        .where("projectId", '==', projectId)
        .limit(10)
        .get()
    console.log(result.docs.pop()?.data())
        
    return Promise.resolve(result.docs.pop()?.data() as model.proposal)
    
        
}





export async function DeleteIconbyId(projectId: string){
     // [START delete_document]
        let deleteDoc = firestore.collection("proposal").doc(projectId).delete()

        // [END delete_document]

         return deleteDoc.then(res => {
         console.log('Delete: ', res);
        });


}

export async function getProjectById(projectId: string): Promise<model.Project>{
    let result = await  firestore.collection("projects")
        .where("project_id", '==', projectId)
        .limit(1)
        .get()
    return Promise.resolve(result.docs.pop()?.data() as model.Project)
}

export async function fetchProject(path: string): Promise<model.Project>{
    let result = await firestore.doc(path).get()
    return Promise.resolve(result.data() as model.Project)
}

export async function createProject(project: model.Project): Promise<model.Project>{
    project.project_id = uuid()
    project.created_at = new Date()
    project.currentStep = 0
    let result = await firestore
        .collection('projects').doc(project.project_id)
            .set(project)

    return fetchProject(`projects/${project.project_id}`)
}

export async function getProjects(userId: string): Promise<Array<model.Project>>{
    let result = await firestore.collection("projects")
        .where("user_id", '==', userId)
        .get()
    let projects = result.docs.map( p => p.data() as model.Project)
    return Promise.resolve(projects)
}

export async function userLogout(){
    firebase.auth().signOut()
}