import React, { useState } from 'react'
import { CssBaseline, Container, Paper, Typography, Box, TextField, Button } from '@mui/material'
import firebase from "utils/firebase";
import NavBar from 'components/NavBar'

const FeedbackPage = () => {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [feedback, setFeedback] = useState('')

    const submit = async () => {
        setLoading(true)
        let fnRef = firebase.functions().httpsCallable("submitFeedback")
        await fnRef({ email: email, feedback: feedback})
        setFeedback('')
        setLoading(false)
    }
    return (
        <>
            <CssBaseline/>
            <NavBar />
            <Container maxWidth="sm">
                <Paper>
                    <Box p={2}>
                        <Typography variant="h1">Feedback</Typography>
                        <Box pt={1}>
                            <TextField label="Email" onChange={(e) => setEmail(e.target.value)}/>
                        </Box>
                        <Box pt={1}>
                            <TextField 
                                label="Feedback" 
                                multiline 
                                rows={10}
                                placeholder="Tell us about your experience"
                                onChange={(e) => setFeedback(e.target.value)}/>
                        </Box>
                        <Box pt={1}>
                            <Button 
                                variant="contained" 
                                color="primary"
                                onClick={() => submit()}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </>
    )
}

export default FeedbackPage