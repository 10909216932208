import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {Avatar, Container, Divider, IconButton, TableContainer} from "@mui/material";
import {Item} from "../../model";
import makeStyles from '@mui/styles/makeStyles';
import SendIcon from "@mui/icons-material/Send";
import proposalLogo from "../../components/proposal_logo.png";
import {Project} from "model";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Logo from '../../components/logo1024.png';
import ProposalPDF from './ProposalPDF';

const useStyles = makeStyles((theme) => ({
    ordername: {
        fontSize: "48px",
        fontWeight: 700,
        marginRight: "14px",
    },
    buttonText: {
        fontSize: "20px",
        marginRight: "0.5em",
    },
    projectTitle: {
        color: "#577590",
        marginBottom: "8px",
        width: "171px",
        height: "26px",
    },
    subTitle: {
        color: "#C5C5C5",
        fontSize: "16px",
        marginBottom: "6px",
    },
    amount: {
        fontSize: "23px",
        fontWeight: 700,
    },
    imageBox: {
        width: "253px",
        height: "253px",
        borderStyle: "dashed",
        marginTop: "60px",
        marginLeft: "52px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        FontSize: "25px",
        color: "#C5C5C5",
    },
    uploadText: {
        fontSize: "25px",
        height: "6vh",
        width: "10vw",
        textAlign: "center"
    },
    breakpoint: {
        values: {
            xs: 0,
            sm: 600,
            md: 1400,
            lg: 1280,
            xl: 1920,
        }
    },
    input: {
        display: 'none',
    }
}));

interface Props {
    resources: Item[],
    project: Project | undefined,
    onBack: () => {}
}

const ProposalHeader: React.FC = () => {
    return (
        <Box display="flex" alignItems="end" paddingBottom="1rem"> 
            <Box flexGrow={1} paddingBottom="1rem">
                <Typography variant="h1">Proposal for Client</Typography>
            </Box>
            <Box width={128} height={128}>
                {/* <ProposalLogoDropZone/> */}
                <Avatar src={Logo} style={{width: "100%", height: "100%"}}/>
            </Box>
        </Box>
    )
}

type ProposalStageProps = {
    name: string,
    items: Item[],
    markup: number
}

const ProposalStage: React.FC<ProposalStageProps> = (props: ProposalStageProps) => {
    const calculateMarkup = (cost: number) => {
        let temp = cost
        if(props.markup > 0){
            temp = temp + (cost * props.markup/100)
        }else{
            temp = cost
        }
        return (temp).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }
    return (
        <Box marginBottom="15mm">
            <Grid container alignItems="center">
                <Grid item xs={10}>
                    <Typography variant="h5">{props.name}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography>Subtotal</Typography>
                </Grid>
            </Grid>
            <Divider variant="fullWidth" style={{marginTop: "16px", marginBottom: "16px"}}/>
            {
                props.items.filter( item => item.selected).map( item => 
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography>{item.name}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>{calculateMarkup(item.cost)}</Typography>
                        </Grid>
                    </Grid>
                )
            }
        </Box>
    )
}

const Proposal: React.FC<Props> = (props: Props) => {
    const stageNames = [
        "Pre Production",
        "Production",
        "Post Production",
        "Equipment",
        "Travel",
        "Miscellaneous",
    ];

    const download = async () => {
    
        const pdf = new ProposalPDF(Logo)

        

        stageNames.forEach( name => {
            getStageResources(name)
                .filter( item => item.selected)
                .forEach( item => {
                    pdf.addItem(item)
                    pdf.addSpace(10)
                })
            // pdf2.addSpace(20)
        })
        
        pdf.drawTotal()
        pdf.download()
    }
    const getEstimate = () => {
        if (props.resources.length === 0) {
            return 0;
        }

        return props.resources
            .filter( item => item.selected)
            .map((r) => r.cost)
            .reduce((sum, current) => sum + current);
    };

    const getStageResources = (stage: string): Item[] => {
        return props.resources.filter((r) => r.category === stage);
    };

    const calculateMarkup = (cost: number): string => {
        let markup = props.project?.markup ?? 0
        let temp = cost
        if(markup > 0){
            temp = cost + (cost * markup/100)
        }else{
            temp = cost
        }

        return (temp).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }

    const classes = useStyles();

    return (
        <Container maxWidth={"lg"}>
            <Box m="2rem">
                <Box
                    mb="1em"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <IconButton onClick={() => props.onBack()} size="large">
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h1">{props.project?.project_name}</Typography>
                    </Box>
                    <Button
                        color="primary"
                        onClick={download}
                        variant="contained"
                        size="medium"
                        endIcon={<SendIcon/>}
                    >
                        Export as PDF
                    </Button>
                </Box>
                <Paper style={{width: "216mm", height: "279mm"}} id="page-1">
                    <Box p="10mm" style={{height: "100%"}} display="flex" flexDirection="column" justifyContent="space-between" border={1}>
                        <Box>
                            <ProposalHeader />
                            <Box marginBottom="15mm">
                                <Divider variant="fullWidth" style={{marginBottom: "16px"}}/>
                                <Grid container>
                                    <Grid xs={4}> 
                                        <Typography className={classes.subTitle}>
                                            Project Name
                                        </Typography>
                                        <Typography style={{marginBottom: "16px"}}>
                                            {props.project?.project_name}
                                        </Typography>
                                    </Grid>

                                    <Grid xs={4}>
                                        <Typography className={classes.subTitle}>
                                            Shoot Date
                                        </Typography>
                                        <Typography style={{marginBottom: "16px"}}>
                                            {props.project?.project_name}
                                        </Typography>
                                    </Grid>

                                    <Grid xs={4}>
                                        <Typography className={classes.subTitle}>
                                            Deliverable
                                        </Typography>
                                        <Typography style={{marginBottom: "16px"}}>
                                            {props.project?.project_name}
                                        </Typography>
                                    </Grid>

                                </Grid>

                                <Grid container>
                                    <Grid xs={4}> 
                                        <Typography className={classes.subTitle}>
                                            Email
                                        </Typography>
                                        <Typography style={{marginBottom: "16px"}}>
                                            {props.project?.project_name}
                                        </Typography>
                                    </Grid>

                                    <Grid xs={4}>
                                        <Typography className={classes.subTitle}>
                                            Location
                                        </Typography>
                                        <Typography style={{marginBottom: "16px"}}>
                                            {props.project?.project_name}
                                        </Typography>
                                    </Grid>

                                </Grid>
                            </Box>

                            {
                                stageNames
                                    .filter( name => getStageResources(name).length > 0)
                                    .map( name => <ProposalStage 
                                                        name={name} 
                                                        items={getStageResources(name).filter( item => item.selected)}
                                                        markup={props.project?.markup ?? 0}
                                                    /> )
                            }

                            <Divider variant="fullWidth" style={{marginBottom: "16px"}}/>

                            <Box>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Typography className={classes.amount}>Total</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography className={classes.amount}>
                                            {calculateMarkup(getEstimate())}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                                <Avatar alt="logo" src={proposalLogo}/>
                                Created by PipApp
                            </Box>
                            <Box>
                                Page 1 of 1
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
};

export default Proposal;
