import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';

import {ReactComponent as GoogleLogo} from './google.svg'
import FacebookIcon from '@mui/icons-material/Facebook';

const WhiteButton = withStyles({

    root: {
        background: "white",
        '&:hover': {
            background: "white",
        }
    }
})(Button)
const CreateButton = withStyles({
    root: {
        background: "white",
        '&:hover': {
            background: "white",
        },
        width:"37px",
        height:"37px",
        padding:"0px",
        borderRadius:"0",
        minWidth:"37px"
    }
})(Button)

const CloseButton = withStyles({
    root: {
        // padding: "0px 30px 0px 30px",
        color: "#EDEDED",
        padding: "30px",
        width: "21px",
        height: "21px",
        transitions: "0.3s",
        '&:hover': {
            color: "black",
        }
    }
})(Button)
const DiscardButton = withStyles({
    root: {
        textAlign: "left",
        font: "Regular 20px/44px Nunito Sans",
        letterSpacing: "0px",
        color: "#C5C5C5",
        opacity: "1",
        right: "41px",
        fontWeight: 400,
    }
})(Button)
const AddMoreItemButton = withStyles({
    root: {
        padding: "5px",
        background: "rgba(4,175,170,0.2)",
        borderRadius: "5px",
        width: "225px",
        height: "51px",
        fontWeight: 400,
    }
})(Button)
const SaveAddButton = withStyles({
    root: {
        color: "#D37E48",
        width: "324px",
        height: "60px",
        border: "1px solid #D37E48",
        borderRadius: "38px",
        opacity: "1",
    }
})(Button)

const BlueButton = withStyles({
    root: {
        background: "#3B5998",
        color: "white",
        '&:hover': {
            background: "#3B5998"
        }
    }
})(Button)

const SendProposalButton = withStyles({
    root: {
        whiteSpace: "nowrap",
        background: "#89B6A5",
        width:"267px",
        height:"60px",
        color: "white",
        '&:hover': {
            background: "#89B6A5"
        }
    }
})(Button)
const CancelPlan = withStyles({
    root: {
        whiteSpace: "nowrap",
        background: "#FFF",
        fontSize:"12px",
        padding:"15px",
        border: '1px solid black',
        color: "black",
        '&:hover': {
            background: "#DC143C"
        }
    }
})(Button)
const ChangePlan = withStyles({
    root: {
        whiteSpace: "nowrap",
        background: "#1E90FF",
        
        fontSize:"12px",
        padding:"15px",
        marginLeft:"10px",
        color: "white",
        '&:hover': {
            background: "#6495ED"
        }
    }
})(Button)

const NavBarButton = withStyles({
    root: {
        borderRadius: 0.0,
        boxShadow: "none",
        width: "195px",
        height: "75px",
        '&:hover': {
            boxShadow: "none",
        }
    }
})(Button)

const CreateAccountButton = withStyles({
    root: {
        borderRadius:"38px",
        display:"flex",
        justifyItems:"center",
        alignItems:"center",
        background: "#FFFFFF",
        width:"415px",
        height:"60px",
        color: "#E5A63D",
        fontSize:"20px",
        fontFamily:"Nunito Sans",
        fontWeight:"bold",
        '&:hover': {
            background: "#FFFFFF"
        }
    }
})(Button)
const CancelButton = withStyles({
    root: {
        color:"#c5c5c5",
        width:"65px",
        height:"27px",
        fontWeight:"normal",
        '&:hover': {
            background: "transparent"
        }
    }
})(Button)

const ConfirmSubmissonButton = withStyles({
    root: {
        background: "#04afaa",
        minWidth:"277px",
        height:"60px",
        color: "white",
        fontWeight:"bold",
        '&:hover': {
            background: "#04afaa"
        }
    }
})(Button)


interface ContinueWithGoogleProps {
    onClick: () => void
}
const ContinueWithGoogle: React.FC<ContinueWithGoogleProps> = (props) => {
    return <WhiteButton
        fullWidth
        startIcon={<GoogleLogo />}
        onClick={props.onClick}
    >
        Continue With Google
    </WhiteButton>
}

interface ContinueWithFacebookProps {
    onClick: () => void
}
const ContinueWithFacebook: React.FC<ContinueWithFacebookProps> = (props: ContinueWithFacebookProps) => {
    return <BlueButton
        fullWidth
        startIcon={<FacebookIcon />}
        onClick={props.onClick}
    >
        Continue With Facebook
    </BlueButton>
}

export {
    WhiteButton,
    NavBarButton,
    CreateButton,
    SendProposalButton,
    CancelButton,
    ConfirmSubmissonButton,
    CloseButton,
    DiscardButton,
    AddMoreItemButton,
    SaveAddButton,
    CreateAccountButton,
    ContinueWithGoogle,
    ContinueWithFacebook,
    CancelPlan,
    ChangePlan
}
