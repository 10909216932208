import React, {useEffect, useState} from 'react'

import NavBar from 'components/NavBar'

import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import ProjectCard from './ProjectCard'
import Container from "@mui/material/Container";
import {useNavigate} from "react-router-dom";
import withStyles from '@mui/styles/withStyles';

import {auth} from "utils/firebase";
import {Project} from "model";
import * as projectApi from 'api/ProjectApi'

const SearchField = withStyles({
    root: {
        '& label.MuiFormLabel-root': {
            color: '#577590',
            fontSize: '20px'
        },
        '& label.Mui-focused': {
            color: '#577590',
        },
        '& .MuiInputBase-input': {
            paddingTop: 16,
            paddingBottom: 16,
        },
        '& .MuiInput-underline': {
            color: '#1E212B',
            fontSize: '20px'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            borderBottomColor: '#577590',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#DCDCDC',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#577590',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
    },
})(TextField);

const GreenButton = withStyles({
    root: {
        fontSize: "20px",
        lineHeight: "27px",
        marginLeft: "16px",
        padding: "16px 28px 16px 28px",
        color: "#89B6A5",
        background: "#E1EAE7",
        boxShadow: "none",
        textTransform: "none",
        '&:hover': {
            background: "#E1EAE7",
            boxShadow: "none",
        }
    }
})(Button)
const StyledButton =withStyles({
    root: {
        padding: "16px 28px 16px 28px",
        textTransform: "none",
        marginLeft: "16px",
    }
})(Button)

const ProjectList = () => {
    const history = useNavigate()
    const [projects, setProjects] = useState<Project[]>([])

    useEffect(() => {
        let userId = auth.currentUser?.uid!!
        projectApi.getProjects(userId)
            .then((data) => setProjects(data))
    }, [])

    const onProjectClick = (projectId: string) => {
        history(`/project/${projectId}`)
    }

    return (
        <>
            <CssBaseline/>
            <NavBar />
            <Container maxWidth="lg">
                <Box pb={8}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h1">
                            Current Projects {projects.length > 0 && <>{`(${projects.length})`}</> }
                        </Typography>
                        {/* <GreenButton variant="contained">
                            Approved
                        </GreenButton>
                        <StyledButton>
                            Pending
                        </StyledButton> */}
                        <Box flexGrow="1"/>
                        {/* <SearchField placeholder="Search Projects..."
                                     InputProps={{
                                         endAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                         ),
                                     }}
                        /> */}
                    </Box>
                    <Box mt={4}>
                        <Grid container spacing={2}>
                            { projects.map( p =>
                                <Grid item xs={4}>
                                    <ProjectCard project={p} onClick={(projectId) => onProjectClick(projectId)}/>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </>
    )
}

export default ProjectList