import React from 'react';
import {ListItem, ListItemText} from '@mui/material'

interface Props {
    name: string,
    cost: number,
}

const LineItem: React.FC<Props> = (props: Props) => {
    return (
        <ListItem style={{padding: 0}}>
            <ListItemText primary={`- ${props.name}`} />
            <ListItemText primary={`$${props.cost}`} style={{textAlign: "right"}}/>
        </ListItem>
    )
}

export default LineItem;