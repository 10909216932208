import {firestore} from "../utils/firebase";
import * as model from "../model";

export async function getStripeProfile(uid: string): Promise<model.StripeUser>{
    console.log(uid)
    let result = await firestore.collection("stripe_customers")
            .doc(uid)
            .get()
    console.log(result.data())
    return Promise.resolve(result.data() as model.StripeUser)
}

export async function addPaymentMethod(uid: string, paymentMethod: string){
    await firestore.collection("stripe_customers")
        .doc(uid)
        .collection('payment_methods')
        .add({ id: paymentMethod });
}

export async function addPayment(uid: string, paymentMethod: string, currency: string, amount: number){

    const data = {
        payment_method: paymentMethod,
        currency,
        amount: amount,
        status: 'new',
    };

    await firestore
        .collection('stripe_customers')
        .doc(uid)
        .collection('payments')
        .add(data);
}