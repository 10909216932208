import React from 'react';
import { Box, Card, CardActionArea, CardActions, CardContent, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete'
import {Item, ItemType} from 'model'
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles((theme) => ({
    default: {
        borderRadius: "16px"
    },
    selected: {
        // height: "100%",
        borderColor: "#04AFAA",
        color: "#04AFAA",
        border: "2px solid",
        borderRadius: "16px"
    },
}));


interface Props {
    item: Item,
    selected: boolean,
    editMode: boolean,
    onItemClick: () => void,
    onItemEdit: (item: Item) => void,
    onItemRemove: (item: Item) => void
}

const StageResource: React.FC<Props> = (props: Props) => {
    const classes = useStyles()
    return (
        <Card variant="outlined" className={ props.selected ? classes.selected : classes.default }>
            <CardActionArea onClick={() => props.onItemClick()}>
                <Box style={{height: '164px'}} display="flex" flexDirection="column" justifyContent="space-between">
                    <CardContent>
                        {props.item.name}
                    </CardContent>
                    <CardContent style={{paddingBottom: "8px"}}>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                ${props.item.cost} 
                                {
                                    props.item.type === ItemType.HOURLY && ` x ${props.item.hours ?? 0} hours`
                                }
                            </Grid>
                        </Grid>
                    </CardContent>
                </Box>
            </CardActionArea>
            {props.editMode &&
                <CardActions>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <>
                                <IconButton onClick={() => props.onItemEdit(props.item)} size="large">
                                    <EditIcon/>
                                </IconButton>
                                <IconButton onClick={() => props.onItemRemove(props.item)} size="large">
                                    <DeleteIcon/>
                                </IconButton>
                            </>
                        </Grid>
                        <Grid item>
                            <Box display="flex" alignItems="center">
                                { props.selected && <CheckCircleIcon/> }
                            </Box>
                        </Grid>
                    </Grid>
                </CardActions>
            }
        </Card>
    );
}

export default StageResource;