import React from 'react'

import Card from '@mui/material/Card'
import CardActionArea from "@mui/material/CardActionArea";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

import {Project} from 'model'

interface ProjectCardProps {
    project: Project,
    onClick: (projectId: string) => void
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: 0,
        paddingTop: "65.11%", // 16:9
        position: "relative",
        borderRadius: "5px",
        boxShadow: "0px 3px 6px #C4C4C429"
    },
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        position: "absolute",
        padding: theme.spacing(6),
    },
    info: {
        display: "flex",
        alignContent: "stretch"
    },
    icon: {
        height: "100%",
    }
}))

const ProjectCard: React.FC<ProjectCardProps> = (props) => {
    const classes = useStyles()
    return (
        <Card className={classes.root}>
            <CardActionArea className={classes.content} onClick={() => props.onClick(props.project.project_id)}>
                <div className={classes.info}>
                    {/* <Avatar variant="rounded" className={classes.icon} src={ProjectIcon}/> */}
                    <Box ml={2}>
                        <Typography variant="h3">{props.project.project_name}</Typography>
                        <Typography variant="subtitle1" color="primary">{props.project.client_name}</Typography>
                    </Box>
                </div>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="body1" color="textSecondary">Project Estimate</Typography>
                        <Typography variant="body2">${props.project.estimate}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body1" color="textSecondary">Shoot Date</Typography>
                        <Typography variant="body2">{props.project.shoot_date}</Typography>
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    )
}

export default ProjectCard