import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Backdrop, CircularProgress, Input, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import {auth} from "../../utils/firebase";
import * as PaymentAPI from "../../api/PaymentApi";
import {CreatePaymentMethodData} from "@stripe/stripe-js";
import firebase from "utils/firebase";
import SubscriptionPlanSelection from "./SubscriptionPlanSelection";
import * as SubscriptionApi from "api/SubscriptionApi";
import {SubscriptionPlan} from "model";

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            fontSize: "18px",
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'Source Code Pro, monospace',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#9e2146',
        },
    },
};

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
    body: {
        fontSize: "20px",
        shadowColor: '#000',
        shadowOffset: { width: 1, height: 1 },
        shadowOpacity: 1,
    },
    layout: {

        width: 'auto',
        height: '100%',
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        background: '#F8F8F8'
    },
    plan: {
        padding: "0px"
    }
    , CurrentPlan: {
        paddingTop: "10px",
        padding: "3rem"
    }, paymentTopContainer: {
        background: "#FFF"

    }
    , paymentContainer: {
        padding: "3em",

    }
    , paymentTitle: {
        textAlign: "left",
        fontWeight: 700,
        fontSize: "23px",
        padding: "3rem",
        paddingBottom: "10px"
    }, divspacebetween: {
        display: "Flex",
        padding: "10px",
        paddingLeft: "0px",
        paddingRight: "0px",

    }, divspacebetweenlast: {

        display: "Flex",
        padding: "10px",
        paddingLeft: "0px",
        paddingRight: "0px",

    }
    , startformEnd: {


        textAlign: "right",
        padding: "0px"
    }, startformleft: {
        fontWeight: 500,
        textAlign: "left",
        padding: "0px"
    }
}));

const SubscriptionForm = () => {

    const stripe = useStripe();
    const elements = useElements();
    const [plans, setPlans] = React.useState<Array<SubscriptionPlan>>([]);
    const [planId, setPlanId] = React.useState("");
    const [coupon, setCoupon] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined | null>(undefined);

    useEffect(() => {
        const fetchData = async () => {
            let data = await SubscriptionApi.getSubscriptionPlans()
            setPlans(data)
            setPlanId(data[0].id)
        }
        fetchData()
    }, [])

    const handleSubmit = async () => {
        console.log("Submit...")
        setLoading(true)
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            setLoading(false)
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        const createPaymentMethodResp = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        } as CreatePaymentMethodData);

        let userId = auth.currentUser?.uid!!
        const stripeUser = await PaymentAPI.getStripeProfile(userId)
        // Use your card Element with other Stripe.js APIs

        if (createPaymentMethodResp.error) {
            console.log('[error]', createPaymentMethodResp.error);
            setLoading(false)
            setError(createPaymentMethodResp.error?.message)
            return
        }

        console.log('[PaymentMethod]', createPaymentMethodResp.paymentMethod?.id);

        let subscribe = firebase.functions().httpsCallable("processSubscibe")
        let subscribeResult = await subscribe({
            customerId: stripeUser.customer_id,
            paymentMethodId: createPaymentMethodResp.paymentMethod?.id,
            priceId: planId,
            coupon: coupon,
        }).catch(error => {
            setLoading(false)
            setError(error.message)
        })
        console.log(subscribeResult)
        if(subscribeResult !== undefined){
            window.location.reload()
        }
    }
    const handleCancel = async () => {
        await firebase.auth().signOut()
    }

    const classes = useStyles()
    return (
        <Box p="2rem">
            <form>
                <Typography variant="h1">Select a plan</Typography>
                <Box mt={4} mb={4}>
                    <Grid container spacing={2}>
                        {plans.map(p =>
                            <Grid key={p.id} item xs={6}>
                                <SubscriptionPlanSelection
                                    plan={p}
                                    selected={planId === p.id}
                                    onSelect={(id) => setPlanId(id)}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Box>
                <Typography variant="h1">Payment Details</Typography>
                <Box my={2} borderRadius="20px">
                    <CardElement id="card-element" options={CARD_ELEMENT_OPTIONS} />
                </Box>
                <Box my={2} borderRadius="20px" width="100%">
                    <Input placeholder="Coupon code" fullWidth onChange={(e) => setCoupon(e.target.value)} />
                </Box>
                {error !== undefined &&
                    <Box className="card-errors" role="alert">{error}</Box>
                }
                <Button variant="contained" color="primary" onClick={() => handleSubmit()}>Subscribe</Button>
                <Button variant="contained" onClick={() => handleCancel()}>Cancel</Button>
            </form>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default SubscriptionForm