import React, {useState} from 'react'
import makeStyles from '@mui/styles/makeStyles';
import {signUpUser} from 'api/UserApi';
import {Box, Grid, TextField, Typography} from '@mui/material';
import {CreateAccountButton} from 'components/buttons/Buttons';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '1080px',
    width: '1920px'

  },
  left: {
    background: '#E5A63D',
    height: '100%',
    paddingLeft: '60px',
    paddingRight: '70px',
    paddingTop: '135px'
  },
  right: {
    background: 'black',
    backgroundImage: "url(/background.png)",
    height: '100%',
    width: "180%"
  },
  logo: {
    display: 'block',
    width: '135px',
    height: '132px',
    backgroundSize: 'cover',
    backgroundColor: '#04AFAA',
    backgroundPosition: 'center center',
    backgroundImage: "url(/logo1024.png)",
    boxShadow: "0px 5px 10px #47474726",
    borderRadius: "64px"
  },
  title: {
    color: "#FFFFFF",
    fontSize: "48px",
    fontFamily: "Nunito Sans",
    fontWeight: "bold"
  },
  formInput: {
    color: "#FFFFFF66",
    fontSize: "24px"

  },
  formtitle: {
    color: "#FFF",
    fontSize: "20px",
    marginBottom: "19px",
    marginTop: "30.5px"
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: "35px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: "white",
    '&:hover': {
      background: "white"
    }
  },
  textinput: {
    borderBlockColor: 'red',
    borderBottomWidth: 2,

  },

  password: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  passwordRemind: {
    fontSize: "14px",
    color: "#FFFFFFBF"
  },
  policy: {
    marginTop: "25.5px",
    marginBottom: "30px",
    fontSize: "14px",
    color: "#FFFFFF"
  },
  hasaccount: {
    marginTop: "26px",
    color: "#FFFFFF"

  },
  floatText: {
    color: "#FFFFFF",

    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    marginLeft: "105px",

  },
  underline: {
    width: " 557px",
    display: "block",
    marginTop: "13.5px",
    marginBottom: "18.5px"
  },
  desc: {
    fontSize: "24px",
    fontFamily: "Nunito Sans",
    color: "#FFFFFF",
    width: "440px",
    height: "68px",
  },
  desc2: {
    height: "27px",
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "55px",
    display: "flex",
    alignItems: "center"
  },
  iconslider: {
    color: "#E5A63D",
    marginLeft: "13px"
  },
  formcontrol: {
    width: "100%",
  }
}));
const SignUpPage = () => {
  const [username, setUsername] = useState('')
  const [fullName, setFullname] = useState('')
  const [companyname, setCompanyname] = useState('')
  const signIn = () => {

    signUpUser(username, values.password, fullName, companyname);

  }
  interface State {
    password: string;
    showPassword: boolean;
  }
  const [values, setValues] = React.useState<State>({
    password: '',
    showPassword: false,
  });
  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const classes = useStyles();
  return <>

    <Grid container className={classes.container}>
      <Grid className={classes.left} item xs={12} md={4}>
        <div className={classes.logo} />
        <Typography className={classes.title}>Sign up</Typography>

        <form className={classes.form} noValidate >
          <Typography className={classes.formtitle}>Email address</Typography>
          <TextField classes={{ root: classes.textinput }}
            margin="none"
            required
            fullWidth
            id="email"

            name="email"
            autoComplete="email"
            autoFocus
            placeholder="Enter your Email Address"
            onChange={(e) => setUsername(e.target.value)}

          />
          <Box className={classes.password}>
            <Typography className={classes.formtitle}>Password</Typography>
            <Typography className={classes.passwordRemind}>Must be at least 8 characters long.</Typography>
          </Box>

          <FormControl className={classes.formcontrol} >
            <Input
              margin="none"
              required
              fullWidth
              autoComplete="current-password"
              id="password"
              placeholder="Enter your password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large">
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Typography className={classes.formtitle}>Full Name</Typography>
          <TextField className={classes.textinput}
            margin="none"
            required
            fullWidth
            name="fullname"
            type="text"
            id="fullname"
            autoComplete="current-fullname"
            placeholder="e.g.John Doe"
            onChange={(e) => setFullname(e.target.value)}

          />
          <Typography className={classes.formtitle}>Company Name</Typography>
          <TextField className={classes.textinput}
            margin="none"
            required
            fullWidth
            name="companyname"
            type="text"
            id="companyname"
            autoComplete="current-companyname"
            placeholder="e.g.Doe Creatives Inc."
            onChange={(e) => setCompanyname(e.target.value)}
          />
          <Typography className={classes.policy}>
            Creating an account means you’re okay with PipApp’s Business Terms of Service and Privacy Policy.
                      </Typography>
          <CreateAccountButton onClick={() => signIn()}>
            Create account
                      </CreateAccountButton>
          <Box className={classes.hasaccount}>
            Already have an account? <a href="./login">Log in</a>
          </Box>
        </form>




      </Grid>
      <Grid className={classes.right} item xs={12} md={8}>

        <Box className={classes.floatText}>
          <Box>
            <Typography className={classes.desc}>An easy budget builder for creatives - that's PipApp in a nutshell.</Typography>
            <hr className={classes.underline}></hr>
            <Typography className={classes.desc2}>Learn More about PipApp<SlideshowIcon className={classes.iconslider}></SlideshowIcon></Typography>
          </Box>

        </Box>
      </Grid>
    </Grid>

  </>;

}

export default SignUpPage;