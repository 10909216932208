import { createTheme, adaptV4Theme } from "@mui/material/styles";

export const signInTheme = createTheme(adaptV4Theme({
    palette: {
        background: {
            default: "#F0F0F0"
        },
        primary: {
            "main": "#04afaa",
            "contrastText": "#fff"
        },
        secondary: {
            "main": "#fff"
        },
        info: {
            "main": "#577590"
        },
        text: {
            primary: "#1E212B",
            secondary: "#C5C5C5"
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1640,
            xl: 1920,
        },
    },
    typography: {
        fontFamily: [
            "Nunito Sans"
        ].join(","),
        body1 : {
            fontSize: "18px"
        },
        h5: {
            textTransform: "capitalize"
        },
        h6: {
            textTransform: "capitalize"
        },
        button: {
            textTransform: "capitalize"
        }
    },
    overrides: {
        MuiAppBar: {
            colorDefault: {
                backgroundColor: "white"
            }
        },
        MuiTypography: {
            root: {
                fontFamily: "Nunito Sans",
            },
            h1: {
                fontWeight: 800,
                fontSize: "2rem",
                lineHeight: "2rem",
                letterSpacing: 0
            },
            h2: {
                fontWeight: 800,
                fontSize: "1.8rem",
                lineHeight: "1.8rem",
                letterSpacing: 0
            },
            h3: {
                fontWeight: 700,
                fontSize: 28,
                lineHeight: "41px",
                letterSpacing: 0
            },
            h4: {
                fontWeight: 800,
                fontSize: 23,
                lineHeight: "44px",
                letterSpacing: 0.28
            },
            h5: {
                fontWeight: 800,
                fontSize: 16,
                lineHeight: "44px",
                letterSpacing: 0.28
            },
            subtitle1: {
                fontWeight: 400,
                fontSize: 18,
                lineHeight: "31px",
                letterSpacing: 0
            },
            caption: {
                fontSize: 20,
                fontWeight: 300,
                lineHeight: "30px",
                letterSpacing: 0,
                color: "#C5C5C5"
            },
            body1: {
                fontSize: 16,
                fontWeight: 300,
                lineHeight: "27px",
                letterSpacing: 0,
            },
            body2: {
                fontSize: 18,
                fontWeight: 300,
                lineHeight: "30px",
                letterSpacing: 0,
            }
        },
        MuiFormLabel: {
            root: {
                color: "#03363d",
                fontWeight: 700,
                // fontFamily: 'Nunito Sans',
                // fontSize: "20pt",
                "&$focused": {
                    color: "#03363d"
                }
            },
        },
        MuiInput: {
            underline: {
                // borderBottom: '2px solid white',
                // '&:before': {
                //     borderBottom: '1px solid #03363d'
                // },
                // '&:after': {
                //     borderBottom: '2px solid #03363d'
                // }
            }
        },
        MuiInputBase: {
            root: {
                color: "#03363d",
                fontSize: "14px"
            }
        },
        MuiButton: {
            root: {
                borderRadius: "20px",
                fontWeight: 700,
                fontFamily: 'Nunito Sans',
                fontSize: "20px",
                paddingLeft: "5%",
                paddingRight: "5%",
            },
            sizeSmall: {
                fontSize: "16px",
                paddingLeft: "2%",
                paddingRight: "2%"
            },
            sizeLarge: {
                fontSize: "20px"
            }
        }
    }
}))