import React, {useEffect, useState} from 'react';
import {
    Box,
    Dialog,
    IconButton,
    TableBody,
    Typography,
    InputAdornment,
    OutlinedInput,
    Select,
    MenuItem,
    Grid,
    FormControl,
    Divider,
    InputLabel,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {AddMoreItemButton, DiscardButton, SaveAddButton} from '../../../components/buttons/Buttons';
import {Item, ItemType} from 'model'
import {v4 as uuid} from "uuid";


interface CustomItemDialogProps {
    items: Item[],
    open: boolean,
    save: (items: Item[]) => void,
    onClose: () => void
}

interface CustomItemProps {
    index: number,
    item: Item,
    onChange: (item: Item) => void,
    onRemove: (item: Item) => void,
}
const StyledTextField = withStyles({
    root: {
        '& label.MuiFormLabel-root': {
            color: '#577590',
            fontSize: '20px'
        },
        '& label.Mui-focused': {
            color: '#577590',
        },
        '& .MuiInputBase-input': {
            paddingTop: 16,
            paddingBottom: 16,
        },
        '& .MuiInput-underline': {
            color: '#1E212B',
            fontSize: '20px'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            borderBottomColor: '#577590',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: '#DCDCDC',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#577590',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => ({
    dialog: {
    },
    btnClose: {
        padding: "30px",
        width: "21px",
        height: "21px",
    },
    title: {
        fontWeight: "lighter",
    },
    table: {},
    header: {
        padding:"16px 16px 0px 16px",
        width: "527.5px",
        textAlign: "left",
        font: "Bold 20px/44px Nunito Sans",
        color: "#577590",
        fontSize: "20px",
    },
    header2: {
        padding:"16px 16px 0px 16px",
        width:"203.5px",
        textAlign: "left",
        font: "Bold 20px/44px Nunito Sans",
        color: "#577590",
        fontSize: "20px",
    },
    row: {
        padding:"16px 0px 48px 0px",
    },
    iconAdd: {
        borderRadius: "2px",
        width: "18px",
        height: "18px",
        backgroundColor: "#04AFAA",
        color: "white",
        opacity: "1",
    },
    txtAddMoreItem: {
        padding: "0px 0px 0px 18px",
        color: "#04AFAA",
        font: "Regular 20px/44px Nunito Sans",
        opacity: "1",
    },
    txtSaveAdd: {
        paddingRight: "26px",
    }
}));

const CustomItem: React.FC<CustomItemProps> = (props: CustomItemProps) => {

    const [name, setName] = useState(props.item.name)
    const [cost, setCost] = useState<string>("" + props.item.cost)
    const [hours, setHours] = useState<string>("" + props.item.hours)

    const [item, setItem] = useState(props.item)

    function indexFormat() {
        if (props.index < 10) {
            return "0" + props.index;
        } else {
            return props.index;
        }
    }
    
    useEffect(() => {
        props.onChange(item)
    }, [item])

    const handleCostChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        console.log(e.target.value)
        setCost(e.target.value)
        if(parseFloat(e.target.value) !== NaN){
            setItem({...item, cost: parseFloat(e.target.value)})
        }
    }

    const handleUpdate = (fieldName: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        console.log(e.target.value)
        let value: string | number = e.target.value
        if(fieldName === "hours" || fieldName === "cost" && value !== ""){
            value = parseFloat(value)
        }
        setItem({...item, [fieldName]: value})
    }

    const handleTypeSelect = (value: ItemType) => {
        setItem({...item, type: value as ItemType})
    }

    return <>
        <Box display="flex" p={1}>
            <Box flexGrow={1} mr={1}>
                <StyledTextField
                    value={item.name}
                    placeholder="Item Name/Description"
                    defaultValue={name}
                    fullWidth
                    onChange={(e) => handleUpdate("name", e)}
                />
                <Box mt={2} mb={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth hiddenLabel variant="outlined">
                                <Select
                                    value={item.type}
                                    onChange={(e) => handleTypeSelect(e.target.value as ItemType)}
                                >
                                    <MenuItem value={ItemType.HOURLY} selected={item.type === ItemType.HOURLY}>Hourly</MenuItem>
                                    <MenuItem value={ItemType.FLAT_RATE} selected={item.type === ItemType.FLAT_RATE}>Flat rate</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                value={cost}
                                onChange={(e) => handleCostChange(e)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            { item.type === ItemType.HOURLY && 
                                <OutlinedInput
                                    onChange={(e) => handleUpdate("hours", e)}
                                    endAdornment={<InputAdornment position="end">Hours</InputAdornment>}
                                />
                            }
                        </Grid>
                        <Grid item xs={3}>
                            { item.type === ItemType.HOURLY && 
                                <TextField
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    value={ (item.hours ?? 0) * item.cost}
                                    disabled={true}
                                    variant="outlined"
                                />
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Button style={{color: "#C5C5C5" }} onClick={() => props.onRemove(props.item)}>
                <DeleteIcon />
            </Button>
        </Box>
        <Divider />
    </>
}
export const CustomItemDialog: React.FC<CustomItemDialogProps> = (props: CustomItemDialogProps) => {

    const [items, setItems] = useState<Item[]>([
        {id: uuid(), type: ItemType.FLAT_RATE, cost: 0.0, hours: 0.0} as Item
    ]);

    const addItem = () => {
        setItems([...items, {id: uuid(), type: ItemType.FLAT_RATE, cost: 0.0, hours: 0.0} as Item])
    }

    const removeItem = (item: Item) => {
        setItems(items.filter((i) => i.id === item.id));
    }

    const handleItemChange = (idx: number, item: Item) => {
        console.log(item)
        items[idx] = item
        setItems(items)
    }

    const clear = () => {
        setItems([
            {id: uuid(), type: ItemType.FLAT_RATE, cost: 0.0, hours: 0.0} as Item
        ])
        props.onClose()
    }

    const save = () => {
        props.save(items)
        clear()
    }


    const classes = useStyles()
    return (
        <Dialog
            open={props.open}
            fullWidth
            maxWidth="md"
            className={classes.dialog}
        >
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h2">Add items to your project</Typography>
                    <AddMoreItemButton onClick={addItem}>
                        <AddIcon className={classes.iconAdd} />
                        <span className={classes.txtAddMoreItem}>Add More Item</span>
                    </AddMoreItemButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                {
                    items.map((i, idx) => <CustomItem index={idx + 1} item={i}
                                                        onChange={(item: Item) => handleItemChange(idx, item)}
                                                        onRemove={(item) => removeItem(item)}
                    />)
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => clear()}>Discard</Button>
                <Button variant="contained" color="primary" onClick={() => save()}>
                    <span>Save</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
}