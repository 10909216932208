import React from 'react'
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import {IconButton, StepButton, useTheme} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import StepConnector from "@mui/material/StepConnector";
import clsx from "clsx";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const Connector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#04afaa',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#04afaa',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        fontSize: 8,
        alignItems: 'center',
    },
    active: {
        color: '#04afaa',
        fontSize: 8
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#04afaa',
        zIndex: 1,
        fontSize: 30,
    },
});

interface StepIconProps {
    active: boolean
    completed: boolean,
}

function StepIcon(props: StepIconProps) {
    const classes = useStepIconStyles();
    const {active, completed} = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ?
                <CheckCircleIcon className={classes.completed}/> :
                <div className={classes.circle}/>
            }
        </div>
    );
}


interface Props {
    activeStep: number,
    steps: Array<string>,
    onStepClick: (name: string) => void
}

const BudgetPlanStepper = (props: Props) => {
    const theme = useTheme()
    return (
        <Stepper style={{background: theme.palette.background.default}}
                 alternativeLabel
                 activeStep={props.activeStep}
                 connector={<Connector/>}>
            {props.steps.map((label) => (
                <Step key={label}>
                    <StepButton disabled={false} onClick={() => props.onStepClick(label)}>{label}</StepButton>
                </Step>
            ))}
        </Stepper>
    )
}

export default BudgetPlanStepper