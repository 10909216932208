import React from 'react'
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {ConfirmCardSetupData, CreatePaymentMethodCardData, CreatePaymentMethodData} from "@stripe/stripe-js";
import makeStyles from '@mui/styles/makeStyles';
import {auth} from "utils/firebase";

import * as PaymentAPI from 'api/PaymentApi';

const useStyles = makeStyles((theme) => ({
    top: {
        padding: "1.5rem",

    }, title: {
        fontSize: "22px",
        fontWeight: 700,
    }, title2: {
        fontSize: "17px",
    }, title3: {
        fontSize: "17px",
        fontWeight: 700,
    }, inputText: {
        width: "100%",
        height: "50px",
        backgroundColor: "#efeff5",
        borderRadius: "10px",
        padding: "1rem",
        border: "none",
        '::placeholder': {
            color: '#A9A9A9',
        },
        fontSize: "16px",
        marginTop: "10px",
        marginBottom: "10px"
    }
}))
const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
        base: {
            iconColor: '#c4f0ff',
            color: '#fff',
            fontWeight: 500,
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {color: '#fce883'},
            '::placeholder': {color: '#87bbfd'},
        },
        invalid: {
            iconColor: '#ffc7ee',
            color: '#ffc7ee',
        },
    },
};

const CheckoutForm = () => {
    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        let userId = auth.currentUser?.uid!!

        const stripeUser = await PaymentAPI.getStripeProfile(userId)
        // Use your card Element with other Stripe.js APIs

        const createPaymentMethodResp = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        } as CreatePaymentMethodData);

        let confirmCardData: ConfirmCardSetupData = {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name: "Testing",
                },
            } as CreatePaymentMethodCardData
        }

        if (createPaymentMethodResp.error) {
            console.log('[error]', createPaymentMethodResp.error);
        } else {
            console.log('[PaymentMethod]', createPaymentMethodResp.paymentMethod);
        }

        const confirmCardSetupResp = await stripe.confirmCardSetup(
            stripeUser.setup_secret,
            confirmCardData
        );

        await PaymentAPI.addPaymentMethod(userId, confirmCardSetupResp.setupIntent?.payment_method!!)
    }

    const submit = async () => {
        const cardElement = elements?.getElement(CardElement);
        // Use your card Element with other Stripe.js APIs

        let paymentMethod, error = await stripe?.createPaymentMethod({
            type: "card",
            card: cardElement,
        } as CreatePaymentMethodCardData);

        if (error) {
            console.log('[error]', error);
        } else {
            console.log('[PaymentMethod]', paymentMethod);
        }
    }

    return (
        <>
            <div id="payment-form" className={classes.top}>
                <div className="w-full inline-block border p-4 rounded-md">
                    <div className={classes.title}>
                        Enter your card details. <br/>
                        Your subscription will start now.
                    </div>
                    <p className={classes.title2}>
                        → Total due now <span>$300</span>
                    </p>
                    <p className={classes.title2}>
                        → Subscribing to{' '}
                        <span className="font-bold">{"PIP APP"}</span>
                    </p>

                    <div>
                        <div>
                            <div>
                                <label className={classes.title3}>
                                    Full name
                                </label>
                                <br></br>
                                <input
                                    className={classes.inputText}
                                    id="name"
                                    type="text"
                                    placeholder="First and last name"
                                    required
                                />
                            </div>
                        </div>
                        <form id="payment-form">
                            <div>
                                <div>
                                    <label className={classes.title3}>
                                        Card
                                    </label>
                                    <div
                                        id="card-element"
                                        className={classes.inputText}
                                    >
                                        <CardElement
                                            options={{
                                                style: {

                                                    base: {

                                                        fontSize: '16px',
                                                        color: '#32325d',
                                                        fontFamily:
                                                            '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
                                                        '::placeholder': {
                                                            color: '#A9A9A9',
                                                        },
                                                    },
                                                    invalid: {
                                                        color: '#9e2146',
                                                    },
                                                },
                                            }}
                                        />
                                    </div>

                                </div>
                            </div>
                            <button onClick={() => submit()}>Add Card</button>
                        </form>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CheckoutForm;