import React from 'react'
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {SubscriptionPlan} from 'model'
import Box from "@mui/material/Box"

const useStyles = makeStyles((theme) => ({
    default: {
        borderRadius: "16px"
    },
    selected: {
        borderColor: "#04AFAA",
        color: "#04AFAA",
        border: "2px solid",
        borderRadius: "16px"
    },
}));


interface Props {
    plan: SubscriptionPlan,
    selected: boolean,
    onSelect: { (id: string): void }
}

const SubscriptionPlanSelection: React.FC<Props> = (props: Props) => {
    const classes = useStyles()
    return (
        <Card variant="outlined" className={props.selected ? classes.selected : classes.default}>
            <CardActionArea style={{ height: "180px" }} onClick={() => props.onSelect(props.plan.id)}>
                <CardContent style={{ height: "100%" }}>
                    <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
                        <div>{props.plan.name}</div>
                        <Typography variant="h5">{props.plan.price}</Typography>
                        <div>{`$${props.plan.description}`}</div>
                        <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="end">
                            {props.selected && <CheckCircleIcon/>}
                        </Box>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default SubscriptionPlanSelection;