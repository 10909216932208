import React, {useEffect, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Box, Divider, List, ListItem, ListItemText, Paper, Slider, Typography} from '@mui/material';
import LineItem from './LineItem';

import {Item, ItemType} from 'model'
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
    costEstimator: {
        padding: theme.spacing(6),
    },
}));

interface Props {
    resources: Item[],
    markup: number,
    onMarkUpSave: (markUp: number) => void
}

const CostEstimator: React.FC<Props> = (props: Props) => {
    const stageNames = ["Pre Production", "Production", "Post Production", "Equipment", "Travel", "Miscellaneous"]
    const [markup, setMarkup] = useState<number>(0)
    const classes = useStyles();

    useEffect(() => {
        setMarkup(props.markup)
    }, [props.markup])

    const calculateMarkUp = (cost: number) => {
        if(markup > 0){
            return cost * (markup/100)
        }else{
            return 0
        }
    }

    const getEstimate = () => {

        if(props.resources.filter( item => item.selected).length === 0){
            return 0;
        }

        let estimate = props.resources
            .filter( item => item.selected)
            .map(r => r.type === ItemType.HOURLY ? r.cost * (r.hours ?? 0) : r.cost)
            .reduce((sum, current) => sum + current);

        return estimate
    }

    const getStageResources = (stage: string): Item[] => {
        return props.resources
            .filter( r => r.category === stage)
            .filter( item => item.selected)
    }

    const getStageEstimate = (stage: string): number => {
        let estimate = getStageResources(stage)
            .map(r => r.cost).reduce((a, b) => a + b, 0)
        return estimate
    }

    const renderStageCost = () => {
        return stageNames.map( (name, idx) => getStageResources(name).length > 0 && <>
            <ListItem key={idx} disableGutters={true}>
                <ListItemText primary={<Typography variant="h4">{name}</Typography>}
                              secondary={<Typography color="primary">
                                  {`$${getStageEstimate(name).toFixed(2)} + ${calculateMarkUp(getStageEstimate(name)).toFixed(2)}`}
                              </Typography>}/>
            </ListItem>
            <List disablePadding={true} dense={true}>
                { getStageResources(name).map( (r, idx) => {
                    return r.type !== ItemType.HOURLY ? 
                        <LineItem key={idx} name={r.name} cost={r.cost}/> 
                        :
                        <LineItem key={idx} name={r.name} cost={(r.hours ?? 0) * r.cost}/> 
                    }
                )}
            </List>
            {/*<Divider />*/}
        </>)
    }

    return (
        <Paper className={classes.costEstimator}>
            <Typography variant="h6">
                PROJECT ESTIMATE
            </Typography>
            <Divider variant="fullWidth" />
            <Box pt={2}>
                Mark Up {markup}%
                <Slider
                    value={markup}
                    defaultValue={0}
                    step={1}
                    onChange={(e, value) => {
                        if(typeof value === "number") {
                            setMarkup(value)
                        }
                    }}
                    onChangeCommitted={(event, value) => {
                        if(typeof value === "number") {
                            props.onMarkUpSave(value)
                        }
                    }}
                />
            </Box>
            <Box mb={2}>
                <List disablePadding={true}>
                    { renderStageCost() }
                </List>
            </Box>
            <Divider variant="fullWidth" style={{backgroundColor: "#48656B", height: 4}}/>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h5" color="inherit" noWrap>
                        SubTotal
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5" color="inherit" noWrap align="right">
                        ${getEstimate().toFixed(2)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h5" color="inherit" noWrap>
                        Mark Up
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5" color="inherit" noWrap align="right">
                        ${calculateMarkUp(getEstimate()).toFixed(2)}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={6}>
                    <Typography variant="h5" color="inherit" noWrap>
                        Total
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5" color="inherit" noWrap align="right">
                        ${(getEstimate() + calculateMarkUp(getEstimate())).toFixed(2)}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default CostEstimator;