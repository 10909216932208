import React from 'react'
import {Container, Grid} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import makeStyles from '@mui/styles/makeStyles';

import NewProjectForm from "./NewProjectForm";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
    layout: {
        background: "#48656B",
        height: "100%"
    },
    container: {
        paddingTop: "5em",
        paddingBottom: "5em"
    },
    paper: {
        background: "white",
        padding: '5em'
    }
}))

const NewProjectPage = () => {
    const classes = useStyles();
    return (
        <>
            <main className={classes.layout}>
                <CssBaseline />
                <Container maxWidth="lg" className={classes.container}>
                    <Paper className={classes.paper}>
                        <Grid container>
                            <Grid item xs={6} lg={4}>
                                <img width="35%" src="pip_film.png" />
                                <Typography variant="h1">Start a new project</Typography>
                            </Grid>
                            <Grid item xs={6} lg={8}>
                                <NewProjectForm />
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </main>
        </>
    )
}

export default NewProjectPage