import firebase, {firestore} from "utils/firebase";

export async function signUpUser(email: string, password: string, fullNameValue: String, companyNameValue: String) {
    firebase.auth()
        .createUserWithEmailAndPassword(email, password).then(function (data) {
            let user = firebase.auth().currentUser;
        }).catch(function (error) {
            let errorCode = error.code;
            let errorMessage = error.message;
        });
}

export async function getSubscription(userId: string) {
    let user = await firestore.collection("stripe_customers")
        .doc(userId)
        .get()
    console.log(user)
    return user.get("subscription")
}