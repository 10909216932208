import React, {useState} from 'react'
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';
import {useNavigate} from "react-router-dom";
import MovieIcon from '@mui/icons-material/Movie';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {NavBarButton} from 'components/buttons/Buttons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import firebase from "utils/firebase";
import {userLogout} from 'api/ProjectApi';
import Logo from './logo1024.png';
import {Backdrop, CircularProgress} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    appBar: {
        boxShadow: '0px 3px 6px #C4C4C429',
        marginBottom: "4rem"
    },
    logo: {
        flexGrow: 1,
        '& .MuiAvatar-root': {
            width: "120px",
            height: "120px",
            position: "absolute",
            top: "15px",
            left: "0"
        }
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}))

const NavBar = () => {
    const classes = useStyles()
    const history = useNavigate()
    const [loading, setLoading] = useState(false)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const logout = () =>{
        userLogout()
       
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const projectDashboard = () => {
        history("/projects")
    }

    const createNewProject = () => {
        history("/new")
    }
    const paymentPage = async () => {
        setLoading(true)
        let fnRef = firebase.functions().httpsCallable("createPortalLink")
        let { data } = await fnRef({ returnUrl: window.location.origin })
        console.log(data.url)
        window.location.assign(data.url);
        setLoading(false)
    }

    const sellerProfile = async () => {
        setLoading(true)
        let fnRef = firebase.functions().httpsCallable("stripe-sellerProfile")
        let { data } = await fnRef({ return_url: window.location.origin, refresh_url: window.location.origin})
        console.log(data.url)
        window.location.assign(data.url);
        setLoading(false)
    }

    const feedbackPage = () => {
        history("/feedback")
    }

    return (
        <>
            <AppBar position="sticky" color="default" className={classes.appBar}>
                <Container maxWidth="xl">
                    <Toolbar>
                        <Box className={classes.logo}>
                            <Avatar src={Logo} sizes="l" />
                        </Box>
                        <Box mr="25px">
                            <NavBarButton color="secondary" size="small"
                                variant="contained"
                                startIcon={<MovieIcon />}
                                onClick={() => projectDashboard()}>Projects</NavBarButton>
                            <NavBarButton color="primary" size="small"
                                variant="contained"
                                startIcon={<AddBoxIcon />}
                                onClick={() => createNewProject()}>New Project</NavBarButton>
                        </Box>
                        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                            <Avatar>G</Avatar>
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={sellerProfile}>Seller Profile</MenuItem>
                            <MenuItem onClick={paymentPage}>Payment</MenuItem>
                            <MenuItem onClick={feedbackPage}>Feedback</MenuItem>
                            <MenuItem onClick={logout}>Logout</MenuItem>
                        </Menu>
                    </Toolbar>
                </Container>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </AppBar>
        </>
    )
}

export default NavBar
