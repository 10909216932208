import React from 'react';
import {Container, Paper} from "@mui/material";
import {Elements} from "@stripe/react-stripe-js";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import stripe from "utils/stripe"
import SubscriptionForm from "./SubscriptionForm";

const OnBoardingPage = () => {
    return (
        <Elements stripe={stripe}>
            <>
                <CssBaseline />
                <Container maxWidth="md" style={{height: "100%"}}>
                    <Box display="flex" alignItems="center" height="100%">
                        <Paper elevation={3}>
                            <SubscriptionForm />
                        </Paper>
                    </Box>  
                </Container>
            </>
        </Elements>
    )
}

export default OnBoardingPage