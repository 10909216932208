import React from 'react'
import {Item} from "../../model";

interface ContextProps {
    resources: Item[],
    addItems: (items: Item[]) => void,
    removeItems: (items: Item[]) => void,
    selectItem: (item: Item) => void,
    deselectItem: (item: Item) => void
    updateItem: (item: Item) => void
}
const BudgetPlanContext = React.createContext<ContextProps>({
    resources: [],
    addItems: () => {},
    removeItems: () => {},
    selectItem: () => {},
    deselectItem: () => {},
    updateItem: () => {}
})

export default BudgetPlanContext