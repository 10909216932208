import React, {useContext, useEffect, useState} from 'react'
import {Route, Routes} from 'react-router-dom'
import NewProjectPage from 'pages/new-project/NewProjectPage'
import BudgetPlanner from 'pages/butget-planner/BudgetPlanner'
import PaymentPage from 'pages/payment/PaymentPage'
import PaymentMethodPage from 'pages/payment/paymentMethodPage'
import ProjectList from 'pages/projects/ProjectList'
import UserContext from 'context/UserContext';
import OnBoardingPage from 'pages/onboarding/OnBoardingPage';
import FeedbackPage from 'pages/feedback/FeedbackPage';
import * as UserApi from 'api/UserApi';

const PrivateRoutes = () => {
    const { user } = useContext(UserContext)
    const [loading, setLoading] = useState(true);
    const [active, setActive] = useState(false);

    useEffect(() => {
        UserApi.getSubscription(user?.uid!!).then((subscription) => {
            if(subscription != null && subscription.status === "active"){
                setActive(true)
            }
            setLoading(false)
        })
    }, [user])

    if(loading){
        return <div>
            loading
        </div>
    }

    if(!active){
        return <OnBoardingPage />
    }

    return (
        <Routes>
            <Route path="new" element={<NewProjectPage />} />
            <Route path="project/:projectId" element={<BudgetPlanner />}/>
            <Route path="projects" element={<ProjectList/>}/>
            <Route path="settings" element={<PaymentPage/>}/>
            <Route path="billings" element={<PaymentMethodPage/>}/>
            <Route path="feedback" element={<FeedbackPage/>}/>
            <Route index element={<ProjectList/>}/>
        </Routes>
    )
}

export default PrivateRoutes;