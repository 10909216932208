import React, {useEffect, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Grid, Box, FormControl, Select, MenuItem, OutlinedInput, InputAdornment} from "@mui/material";
import {Item, ItemType} from "../../../model";

type EditItemDialogProps = {
    open: boolean,
    target?: Item,
    onSave: (item: Item) => void
    onClose: () => void,
}

const EditItemDialog: React.FC<EditItemDialogProps> = ({open, target, onClose, onSave}) => {

    const [cost, setCost] = useState<string>("0.0")
    const [hour, setHour] = useState<string>("0.0")

    const [item, setItem] = useState<Item>()
    
    useEffect(() => {
        if(target) {
            setItem(target)
            setCost("" + target.cost)
            setHour("" + target.hours ?? 0)
        }
    }, [target])


    const save = () => {
        if(item){
            onSave(item)
        }else{
            onClose()
        }
    }

    const handleCostChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        console.log(e.target.value)
        setCost(e.target.value)
        if(parseFloat(e.target.value) !== NaN && item !== undefined){
            setItem({...item, cost: parseFloat(e.target.value)})
        }
    }

    const handleHourChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setHour(e.target.value)
        if(parseFloat(e.target.value) !== NaN && item !== undefined){
            setItem({...item, hours: parseFloat(e.target.value)})
        }
    }

    const handleUpdate = (fieldName: string, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        console.log(e.target.value)
        let value: string | number = e.target.value
        if(fieldName === "hours" || fieldName === "cost" && value !== ""){
            value = parseFloat(value)
            if(item !== undefined){
                setItem({...item, [fieldName]: value})
            }
        }
    }

    const handleTypeSelect = (value: ItemType) => {
        if(item !== undefined){
            setItem({...item, type: value as ItemType})
        }
    }

    if(item === undefined){
        return <></>
    }

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="md"
            onClose={onClose}
        >
            <DialogTitle>Edit Item</DialogTitle>
            <DialogContent>
                <TextField
                    value={item.name}
                    placeholder="Item Name/Description"
                    defaultValue={item.name}
                    fullWidth
                    onChange={(e) => handleUpdate("name", e)}
                />
                <Box mt={2} mb={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl fullWidth hiddenLabel variant="outlined">
                                <Select
                                    value={item.type}
                                    onChange={(e) => handleTypeSelect(e.target.value as ItemType)}
                                >
                                    <MenuItem value={ItemType.HOURLY} selected={item.type === ItemType.HOURLY}>Hourly</MenuItem>
                                    <MenuItem value={ItemType.FLAT_RATE} selected={item.type === ItemType.FLAT_RATE}>Flat rate</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                value={cost}
                                onChange={(e) => handleCostChange(e)}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            { item.type === ItemType.HOURLY && 
                                <OutlinedInput
                                    value={hour}
                                    onChange={(e) => handleHourChange(e)}
                                    endAdornment={<InputAdornment position="end">Hours</InputAdornment>}
                                />
                            }
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>Cancel</Button>
                <Button onClick={() => save()}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditItemDialog