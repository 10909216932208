import React from 'react';
import {BrowserRouter} from "react-router-dom";
import {useAuthState} from 'react-firebase-hooks/auth';
import {auth} from 'utils/firebase'
import PublicRoutes from "routes/PublicRoutes";
import PrivateRoutes from "routes/PrivateRoutes";
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import {signInTheme} from 'theme';
import UserContext from 'context/UserContext';


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


function App() {
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return <div>
      <p>Loading</p>
    </div>
  }

  if (error) {
    return <div>
      <p>error</p>
    </div>
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={signInTheme}>
        <BrowserRouter>
          { user ?
            <UserContext.Provider value={{user}}>
              <PrivateRoutes />
            </UserContext.Provider> :
            <PublicRoutes />
          }
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );

}

export default App;